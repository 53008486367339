/* PackageInfo.css */
.custom-tabs {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 16px;
  }
  
  .custom-tab {
    color: #333;
    text-transform: none;
    font-weight: bold;
    padding: 12px 24px;
    transition: color 0.3s, background-color 0.3s;
  }
  
  .custom-tab:hover {
    background-color: #e0e0e0;
  }
  
  .custom-tab.Mui-selected {
    background-color: #bcd4e8;
    color: white;
  }
  
  .custom-tab-indicator {
    height: 4px;
    background-color: #bcd4e8;
  }
  