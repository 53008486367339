.agent-table {
  margin: 20px auto; 
  padding: 20px;
  max-width: 1200px;
  border-radius: 8px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
  background-color: #ffffff;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  text-align: left;
  padding: 12px;
  border: 1px solid #ddd;
}

th {
  background-color: #2196F3; 
  color: white; 
  font-weight: bold;
}

.agent-th-input{
 background-color: #fff;
}
.agent-th-input-field{
  outline: none;
  border-radius: 5px;
  padding: 5px 5px 5px 10px;
  border: rgb(199, 186, 186) 1px solid;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #e0e0e0; 
  cursor: pointer; 
}

td {
  transition: background-color 0.3s; 
}

td:last-child {
  text-align: center; 
}

@media (max-width: 768px) {
  table {
      display: block; 
      overflow-x: auto; 
      white-space: nowrap; 
  }

  th, td {
      padding: 8px; 
  }
}
.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.3s;
}

.action-button i {
  font-size: 16px; 
  color: #007bff;
}

.action-button:hover {
  background-color: #f0f0f0;
  border-radius: 4px;
}

.edit-button i {
  color: #28a745; 
}

.delete-button i {
  color: #007bff; 
}
/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

/* Popup Container */
.popup {
  background-color: #fff; 
  padding: 20px;
  border-radius: 5px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
  width: 400px; 
}

/* Form Styles */
.popup h2 {
  margin-top: 0; 
}

.popup label {
  display: block; 
  margin-bottom: 10px;
 }

.popup input,
.popup select {
  width: 100%; 
  padding: 8px; 
  margin-top: 5px; 
  border: 1px solid #ccc; 
  border-radius: 4px; 
}

/* Button Styles */
.popup button {
  margin-right: 7px;
  padding: 6px 10px; 
  border: none; 
  border-radius: 4px; 
  cursor: pointer; 
  float: right;
}

.popup button[type="submit"] {
  background-color: #28a745; 
  color: white; 
}

.popup button[type="button"] {
  background-color: #dc3545; 
  color: white; 
}

.popup button:hover {
  opacity: 0.9; 
}
.status-active {
  color: green; 
  font-weight: bold; 
}

.status-inactive {
  color: red; 
  font-weight: bold;
}
.btn-primary{
  background-color: #1975d1;
  padding: 10px 20px;
}
.admin-button i{
  color: aqua;
}