    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .modal {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        width: 400px;
    }

    .modal h3 {
        margin-top: 0;
    }

    .modal form div {
        margin-bottom: 10px;
    }

    .modal input {
        width: 100%;
        padding: 8px;
        margin-top: 5px;
    }

    .modal button {
        margin-right: 10px;
    }

    .modal .btn-secondary {
        background-color: #6c757d;
        padding: 10px;
    }

    .modal .btn-danger {
        background-color: red;
        color: white;
    }

    .company-table {
        padding: 20px;
    }

    .company-table table {
        width: 100%;
        border-collapse: collapse;
    }

    .company-table th, .company-table td {
        padding: 10px;
        border: 1px solid #ccc;
    }

    .action-button {
        cursor: pointer;
        border: none;
        background: none;
        padding: 5px;
    }

    .action-button.edit-button {
        color: #007bff;
    }

    .action-button.delete-button {
        color: red;
    }
