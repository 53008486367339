.view-customer-container{
  padding: 10px;
}

.custom-accordion-container-btn{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.view-customer-container > h1 {
  text-align: center;
  margin: 20px;
  color: #333;
}
.custom-accordion-container {
  width: 50%;
  margin: 30px auto;
}
.custom-accordion-heading {
  border: none;
  border-radius: 8px;
  margin-top: 30px;
  background-color: #2196f3;
  color: #fff;
  font-size: 20px;
}
.custom-accordion-para {
  font-size: 16px;
}

.sign-container{
  margin: 15px auto;
  width: 100%;
}

.canva-container{
 
  z-index: 3;  
  margin: 0 auto;
  width: 500px;
  height: 250px;
}