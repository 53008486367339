/* Overlay Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: overlayFadeIn 0.3s forwards;
}

/* Modal Styling */
.modal {
  display: block;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 50%;
  height: auto;
  padding: 25px;
  text-align: center;
  animation: modalSlideIn 0.3s ease-out forwards;
  box-sizing: border-box; /* Ensure padding is included in width/height calculations */
  position: relative;
}

/* Modal Header Styling */
.modal h2 {
  font-size: 28px;
  color: #333;
  margin: 0 0 20px;
  font-weight: 600;
  text-transform: uppercase;
}

/* Modal Text Styling */
.modal p {
  font-size: 18px;
  color: #555;
  margin: 20px 0;
  line-height: 1.5;
  font-weight: 300;
}

/* Button Container */
.modal-buttons {
  display: flex;
  justify-content: space-around;
  float: right;
}

/* Button Styling */
.modal-buttons button {
 display: flex;
 justify-content: center;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 40%;
  box-sizing: border-box; /* Ensure buttons have correct width calculation */
}

/* Yes Button Styling */
.modal-buttons button:first-child {
  background-color: red;
  color: white;
  border: none;
}

.modal-buttons button:first-child:hover {
  background-color: red;
  transform: translateY(-2px);
}

/* No Button Styling */
.modal-buttons button:last-child {
  background-color: #6c757d;
  color: white;
  border: none;
}

.modal-buttons button:last-child:hover {
  background-color: #6c757d;
  transform: translateY(-2px);
}

/* Keyframe Animations */
@keyframes overlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalSlideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Mobile and Responsive Styling */
@media (max-width: 768px) {
  .modal {
    max-width: 50%; /* Modal width adjusts to 90% of the viewport */
    height: auto; /* Allow height to adjust based on content */
  }

  .modal h2 {
    font-size: 24px; /* Slightly smaller title on smaller screens */
  }

  .modal p {
    font-size: 16px; /* Adjust paragraph size for smaller screens */
  }

  .modal-buttons button {
    font-size: 14px; /* Slightly smaller buttons for mobile */
    padding: 10px 20px; /* Adjust button size for mobile screens */
  }
}

@media (max-width: 480px) {
  .modal {
    max-width: 85%; /* Modal width adjusts even more on smaller devices */
  }

  .modal h2 {
    font-size: 20px; /* Make header smaller for very small screens */
  }

  .modal p {
    font-size: 14px; /* Further reduce text size */
  }

  .modal-buttons button {
    font-size: 14px;
    padding: 8px 15px; /* Smaller buttons on very small screens */
  }
}
