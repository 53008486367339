/* src/css/PaymentInfo.css */

.payment-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }
  
  .payment-card {
    padding: 16px;
  }
  
  .payment-card-header {
    background-color: #f5f5f5;
  }
  
  .payment-fields {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  
  .payment-status-address {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: flex-start;
  }
  
  .address-checkbox {
    margin: 16px 0;
  }
  
  .address-card {
    padding: 16px;
  }
  