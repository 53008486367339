.team-address-container {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.team-address-header{
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.team-address-left-container {
  background-color: #f4f7ff;
  width: 40%;
  padding: 10px;
  flex-direction: column;
  overflow: scroll;
  height: 100vh;
  flex-wrap: wrap;
}

.team-address-right-container {
  width: 60%;
  height: 100vh;

} 
.team-address-left-box {
  width: 95%;
  margin: 20px auto;
  background-color: #ffffff; 
  border-radius: 8px; 
  padding: 20px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.team-address-left-box:hover {
  transform: translateY(-5px); 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}

h2 {
  font-size: 1.6rem; 
  font-weight: 600; 
  color: #333; 
  margin-bottom: 12px;
}

p {
  font-size: 1rem; 
  color: #555; 
  margin-bottom: 8px; 
}

p:last-child {
  margin-bottom: 0; /* Remove bottom margin from the last paragraph */
}

p strong {
  font-weight: 700; /* Make the labels bold */
}

p span {
  color: #007bff; /* Color for the data, to make it stand out */
}

.team-address-left-box .address {
  font-style: italic; /* Make the address stand out with italic style */
}

.team-address-left-box .zip,
.team-address-left-box .city {
  font-weight: 500; /* Slightly bold for these specific fields */
}

@media (max-width: 600px) {
  .team-address-left-box {
    padding: 15px; /* Reduce padding on smaller screens */
  }

  h2 {
    font-size: 1.4rem; /* Adjust heading size for smaller screens */
  }

  p {
    font-size: 0.9rem; /* Adjust paragraph text size */
  }
}

/* .team-address-left-box {
  width: 95%;
  margin: 20px auto;
  background-color: #fff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.team-address-left-box > h2 {
  color: #333;
  font-weight: 700;
  font-size: 1.2rem;
}

.team-address-left-box > p {
  font-size: 1rem;
  color: #555;
} */
