/* General container for the Dropdown page */
.dropdown-page {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Header styling */
  .dropdown-page h2 {
    text-align: center;
    color: #333;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Category selection dropdown */
  .dropdown-page select {
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  
  /* Label styling */
  label {
    font-size: 1rem;
    margin-bottom: 5px;
    display: flex;
    color: #555;
  }
  
  /* Input fields */
  .inputField {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  
  /* Button styling for adding items */
  button {
    background-color: #1975d1;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #1975d1;
  }
  
  /* Display Loading spinner */
  .loading-spinner {
    text-align: center;
    font-size: 1.5rem;
    color: #666;
  }
  
  /* Table Styling */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
  }
  
  table th,
  table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  table th {
    font-weight: bold;
  }
  
  table td {
    font-size: 1rem;
    color: #333;
  }
  
  /* Table header */
  table thead {
    background-color: #f9f9f9;
  }
  
  /* Responsive Table */
  @media screen and (max-width: 768px) {
    .dropdown-page {
      margin: 10px;
      padding: 15px;
    }
  
    /* Ensure input fields and buttons are full-width on smaller screens */
    input[type="text"],
    input[type="number"],
    button {
      width: 100%;
    }
  
    table {
      font-size: 0.9rem;
      margin-top: 20px;
    }
  
    table th,
    table td {
      padding: 8px;
    }
  }
  
  /* Error message for required fields */
  .error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 5px;
  }
  
  /* Modal Overlay */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal stays above other content */
  }
  
  /* Modal content */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1001;
  }
  
  /* Close button in the modal */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
  }
  
  .close-btn:hover {
    color: #ff4d4d; /* Red color on hover */
  }
  
  /* Add more space below the modal form */
  .modal-content input,
  .modal-content button {
    margin-bottom: 15px;
  }
  
  /* Modal Title */
  .modal-content h3 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Modal Button for submitting */
  .modal-content button {
    background-color: #007bff;
    color: white;
    padding: 12px 24px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-content button:hover {
    background-color: #007bff;
  }
  .action-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.3s;
  }
  
  .action-button i {
    font-size: 16px; 
    color: #007bff;
  }
  
  .action-button:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  
  .edit-button i {
    color: #28a745; 
  }
  .delete-button i {
    color: red; 
  }
  .pagination-info {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-controls button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    color: #1975d1;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .pagination-controls button.active {
    background-color: #1975d1;
    color: white;
  }
  
  .pagination-controls button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .pagination-controls button i {
    font-size: 16px;
  }
  
  /* Style for Previous and Next buttons */
  .pagination-controls button:disabled i {
    color: #ccc;
  }
  
  .pagination-controls button:hover:not(:disabled) {
    background-color: #f0f0f0;
  }
  